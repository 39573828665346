<template>
  <div>
    <div class="boxFlex" :style="'background:' + background">
      <h2>teste {{ porcent }} %</h2>
      <div class="datas">
        <input type="date" name="init" id="" v-model="init" required />
        <p>a</p>
        <input type="date" name="finish" v-model="finish" required />
      </div>
      <select name="" id="" v-model="selected">
        <option :value="13">Todos</option>
      </select>
      <button @click="teste">Click</button>
    </div>
  </div>
</template>

<script>
import loader from "@/components/utils/_Load";
import HTTP from "@/api_system";
import { HubConnectionBuilder } from "@aspnet/signalr";
export default {
  props: {
    background: {
      type: String,
      default: "#ffffff00"
    }
  },
  components: {
    loader
  },
  data() {
    return {
      selected: 0,
      init: "",
      finish: "",
      porcent: 0
    };
  },
  methods: {
    signalTeste(uid) {
      let connection = new HubConnectionBuilder()
        .withUrl("//apiteste.pontofacil.fasters.com.br/Jobsignalr")
        .configureLogging(signalR.LogLevel.Information)
        .build();
      connection.serverTimeoutInMilliseconds = 120000;
      connection.on("progress", percent => {
        if (percent === 100) {
          this.porcent = percent;
        } else {
          this.porcent = percent;
        }
      });
      connection
        .start()
        .then(() => {
          connection.invoke("AssociateJob", uid);
          this.$notify({
            group: "erros",
            type: "sucess",
            text: `<i class="icon ion-close-circled">Carregamento Iniciado</i>`
          });
        })
        .catch(err => {
          console.error(err.toString());
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled">Algo deu errado</i>`
          });
        });
    },
    teste() {
      HTTP.post(
        `services/app/Client/ExportClientsByTenantsAnalyticalJob?dateInitial=${this.init}&dateFinale=${this.finish}`,
        [
          {
            id: this.selected
          }
        ],
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          let uid = res.data.result;
          console.log(uid);
          this.signalTeste(uid);
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped>
.boxFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
}
.datas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.datas input {
  height: 35px;
  margin: 0 10px;
  padding: 7px;
  outline: none;
  border: none;
}

select {
  height: 35px;
  margin: 10px;
  width: 130px;
  padding: 7px;
  outline: none;
  border: none;
}

h2,
p {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px !important;
}

h2 {
  font-size: 22px;
  margin-left: 8px;
}

p {
  font-size: 15px;
}

input {
  height: 20px;
  width: 169px;
  background: #357eb8;
  border: 1px solid #e0a638 !important;
  padding: 15px 0 15px 5px;
  color: #fff;
  outline: none;
}

select {
  height: 30px;
  background-color: #357eb8;
  border: 1px solid #e0a638;
  color: #fff;
  width: 200px;
  padding-left: 5px;
  outline: none;
}

button {
  height: 40px;
  width: 115px;
  border-radius: 5px;
  border: 2px solid;
  background-color: #a9a2a2;
  color: #fff;
  cursor: pointer;
  position: relative;
  right: 11px;
  border-radius: 7px;
  font-weight: 500;
  color: #ffff;
  -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 14px;
  background-color: #e0a638;
  border: none;
}

button:hover {
  background-color: #f38235;
}
</style>
